<template>
  <div class="appointment-content">
    <div class="loading-wrapper">
      <SelectPrintableFile v-if="form.id" :form="form" @openPatientIdPrintModal="showBrowserPrintModal" :patientIdSettingsActive="printSettingsActive"/>
      <Loading
        :class="{ icon: true, reload: true, loading }"
        @click="() => !loading && loadAppointmentDetails()"
      />
    </div>
    <FeatureItemsPendencies
      :form="form"
      :validateForm="validateForm"
      :getPayloadData="getPayloadData"
    />
    <div class="patient-status-wrapper">
      <PatientData
        :form="form"
        @selectDefaultHealthPlan="getPatientHealthPlans"
        :appointment="form"
        v-model="form.patient"
        :disabled="!this.userCanSave"
      />
      <div class="vertical-divider" />
      <div class="status-priority-wrapper">
        <SituationSelect
          v-model="form.status"
          :statusTime="form.status_time"
          :validated="validated"
          :validatedStatus="validatedStatus"
          @select="onSelectSituation"
        />
        <PriorityLevelSelect v-model="form.priority_level" />
      </div>
    </div>
    <AppointmentItemsSelect
      :form="form"
      :allPlans="allPlans"
      :plans="plans"
      :healthPlans="healthPlans"
      :defaultHealthPlan="defaultHealthPlan"
      :validated="validated"
      @change="changeAppointmentItems"
      @removeItem="handleRemoveItem"
      @validatedPlans="getPatientHealthPlans"
      @updatePlans="updatePlans"
      :disabled="!this.userCanSave"
    />

    <div class="divider" />

    <div class="row">
      <div class="col-3 appoinment-professional">
        <ProfessionalSelect
          v-model="form.professional"
          :validated="validated && !form.professional && !form.room"
          :professionalId="professionalId"
          :roles="['DOCTOR', 'DOCTOR_MANAGER']"
          :selectProfessional="selectProfessional"
          :clearProfessional="clearProfessional"
          :disabled="!this.userCanSave"
        />
      </div>
      <div class="col-3 appoinment-room">
        <RoomSelect
          v-model="form.room"
          :value="form.room"
          :validated="validated && !form.professional && !form.room"
          :availableProcedures="proceduresRoom"
          :key="roomKey"
          @select="onSelectRoom"
          :clearRoom="clearRoom"
          :clearDate="clearDateTime"
          :disabled="!this.userCanSave"
        />
      </div>
      <b-col cols="3" v-if="withEquipments">
        <b-form-group>
          <label for="health_plan"> Equipamento </label>
          <multiselect
            v-model="form.equipment"
            class="with-border"
            :show-labels="false"
            label="name"
            track-by="id"
            :options="equipments"
            placeholder="Selecionar"
          >
            <template #caret>
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template #noOptions>
              <span class="no-equipments">
                Nenhum equipamento disponível para a sala selecionada
              </span>
            </template>
            <template #noResult> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>
      <div class="col-3" v-if="!withEquipments">
        <b-form-group style="position: relative">
          <label for="link"> Tele-atendimento </label>
          <b-form-input
            id="link"
            autocomplete="off"
            disabled
            :value="getTelemedicineLink(form)"
            class="form-control"
            placeholder="Inserir link"
          />
          <Export
            v-if="form.telemedicine_link"
            class="telemedicine-export"
            @click="openTelemedLink(form)"
          />
        </b-form-group>
      </div>
    </div>

    <b-alert show dismissible :variant="constraintAgeVariant" :class="{'constraint-block' : constraintAgeAction === 'block', 'constraint-warning' : constraintAgeAction === 'warning'}" v-if="constraintAgeAction !== 'allow'">

      <Warning class="warning-icon"></Warning>

      O paciente está fora do limite de idade determinado para o Dr(a) {{this.form.professional.name}}. Para alterar as idades atendidas, <a href="/agenda/configuracoes">clique aqui</a>.
    </b-alert>
    <b-alert show dismissible :variant="constraintHealthPlanVariant" :class="{'constraint-block' : constraintHealthPlanAction === 'block', 'constraint-warning' : constraintHealthPlanAction === 'warning'}" v-if="constraintHealthPlanAction !== 'allow'">

      <Warning class="warning-icon"></Warning>

      O paciente está fora das regras definidas para o Dr(a) {{this.form.professional.name}}. Para alterar as regras, <a href="/agenda/configuracoes">clique aqui</a>.
    </b-alert>

    <div class="divider" />

    <TissModule
      v-if="form.id && form.health_plan?.id"
      :patient="form.patient"
      :appointmentId="form.id"
      :patient_health_plan_id="form.health_plan?.id"
    />
    <DateAndTimeModule
      :form="form"
      :validated="validated"
      :updateForm="(key, value) => (form[key] = value)"
      :key="datetimeKey"
      ref="dateTimeMod"
      @disabledDate="value => (isSelectedDateAvailable = !value)"
      :disabled="!this.userCanSave"
      :onRenderStartTime="onRenderStartTime"
    />

    <div class="divider" />

    <b-form-group>
      <label for="description">
        Descrição
        <span class="help">(Opcional)</span>
      </label>
      <TextArea
        id="description"
        v-model="form.description"
        class="form-control"
        rows="2"
        placeholder="Descrever"
        :maxLength="2000"
        :disabled="!this.userCanSave"
      />
    </b-form-group>

    <div class="mt-4 text-right">
      <span />

      <b-button
        v-if="userHasRoles(['DOCTOR', 'DOCTOR_MANAGER']) && form.id"
        variant="primary"
        @click="onStartClick"
        :disabled="!canStartAttendance || this.validItemProcedure"
        :style="{
          float:
            userHasRole('DOCTOR_MANAGER') || userHasRole('DOCTOR')
              ? 'left'
              : 'right'
        }"
      >
        {{
          form?.attendance?.find(att => att.type === 'ATTENDANCE')
            ? 'Continuar'
            : 'Iniciar'
        }}
      </b-button>

      <b-button
        v-can="'FpAgen3'"
        variant="link"
        class="remove mr-3"
        v-if="
          form.id &&
          [
            'SCHEDULED',
            'CONFIRMED',
            'MISSED',
            'CANCELLED',
            'CANCELLED_BY_PATIENT'
          ].includes(form.status)
        "
        @click="deleteAppointment"
      >
        Apagar agendamento
      </b-button>
      <span id="save">
        <b-button
          v-can="'FpAgen2'"
          variant="primary"
          @click="handleSaveAppointment()"
          :disabled="savingAppointment || !this.userCanSave || !canSaveFitting || this.constraintAgeAction === 'block' || this.constraintHealthPlanAction === 'block' || [
            'CANCELLED_BY_PATIENT',
          ].includes(form.status)"
        >
          {{ form.id ? 'Atualizar' : 'Salvar' }}
        </b-button>
      </span>
      <b-tooltip
        v-if="!canSaveFitting"
        target="save"
        triggers="hover"
        placement="bottom"
      >
        Voce não tem permissão para salvar um agendamento do tipo encaixe, por
        favor selecione um horário disponível
      </b-tooltip>
    </div>
    <BrowserPrintModal v-if="showBrowserPrint"
      :patientId="form.patient?.id"
      :printSettings="patientIdPrintSettings"
      :type="'bracelet'"
      @closeBrowserPrintModal="showBrowserPrint = false"
    />
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import {
  showOnlending,
  canCreateAppointmentTypeFitting
} from '@/utils/permissionsHelp'
import {
  userHasRole,
  userHasRoles,
  getCurrentClinic,
  getCurrentUser,
  userHasPermission
} from '@/utils/localStorageManager'

export default {
  props: {
    appointmentId: String,
    date: Date,
    loadAppointments: Function,
    patientProps: {
      type: Object,
      default: () => ({})
    },
    professionalId: {
      type: Object,
      default: () => ({})
    },
    withEquipments: Boolean,
    setAppointmentId: Function
  },
  components: {
    PatientData: () => import('@/components/AppointmentModal/PatientData'),
    SituationSelect: () =>
      import('@/components/AppointmentModal/SituationSelect'),
    FeatureItemsPendencies: () =>
      import('@/components/AppointmentModal/FeatureItemsPendencies'),
    AppointmentItemsSelect: () =>
      import('@/components/AppointmentModal/AppointmentItemsSelect'),
    ProfessionalSelect: () => import('@/components/General/ProfessionalSelect'),
    RoomSelect: () => import('@/components/General/RoomSelect'),
    TissModule: () => import('@/components/Schedule/TissModule'),
    DateAndTimeModule: () => import('@/components/Schedule/DateAndTimeModule'),
    TextArea: () => import('@/components/General/TextArea'),
    Export: () => import('@/assets/icons/export.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Loading: () => import('@/assets/icons/loading.svg'),
    SelectPrintableFile: () =>
      import('@/components/General/SelectPrintableFile'),
    PriorityLevelSelect: () =>
      import('@/components/AppointmentModal/PriorityLevelSelect'),
    Warning: () => import('@/assets/icons/alert-circle.svg'),
    BrowserPrintModal: () => import('@/components/BrowserPrint/BrowserPrintModal.vue')
  },
  async created() {
    if (this.date) {
      this.form.date = this.date
    }
    this.getPatientIdPrintSetttings()
  },
  data() {
    return {
      loading: false,
      showOnlending: showOnlending(),
      printingResume: false,
      initialItem: null,
      validated: false,
      validatedStatus: false,
      clinic: getCurrentClinic(),
      availableProcedures: [],
      form: this.getDefaultForm(),
      isSelectedDateAvailable: true,
      itemsToDelete: [],
      equipments: [],
      datetimeKey: 0,
      roomKey: 0,
      currentItems: null,
      savingAppointment: false,
      proceduresRoom: [],
      showToast: {
        warnings: []
      },
      healthPlans: [],
      allPlans: [],
      plans:[],
      defaultHealthPlan: null,
      userCanSave: true,
      onRenderStartTime: null,
      validItemProcedure: false,
      patientAge: null,
      professionalConstraint: null,
      constraintAgeAction: 'allow',
      constraintHealthPlanAction: 'allow',
      patientIdPrintSettings: {},
      showBrowserPrint: false,
      printSettingsActive: false
    }
  },
  computed: {
    canCreateAppointmentTypeFitting,
    canStartAttendance() {
      return (
        this.validProcedure(),
        this.form?.appointment_items?.length &&
        ![
          'MISSED',
          'CANCELLED',
          'CANCELLED_BY_PATIENT',
          'FINISHED',
          'IN_PRE_CONSULTATION'
        ].includes(this.form?.status)
      )
    },
    dateTime() {
      return [this.date, this.startTime, this.endTime].join()
    },
    canSaveFitting() {
      return (
        this.form.type !== 'Encaixe' ||
        (this.form.type === 'Encaixe' && this.canCreateAppointmentTypeFitting)
      )
    },
    constraintAgeVariant() {
      if (this.constraintAgeAction === 'block') {
        return 'danger';
      }

      return 'warning';
    },
  },
  methods: {
    userHasRole,
    userHasRoles,
    getDefaultForm: () => ({
      patient: null,
      professional: null,
      date: null,
      status: 'SCHEDULED',
      status_time: null,
      type: 'Agendamento',
      telemedicine_link: null,
      appointment_items: [],
      equipment: {},
      priority_level: null
    }),
    validProcedure() {
      for (const key of Object.keys(this.form.appointment_items)) {
        if (this.form.appointment_items[key].item?.deleted_at) {
            this.validItemProcedure = true;
            break
        }
        this.validItemProcedure = false;
      }
    },
    forceReloadRoom() {
      this.forceReloadDate()
      this.roomKey += 1
    },
    forceReloadDate() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs?.dateTimeMod?.forceReload()
    },
    onSelectSituation() {
      this.form.status_time = this.moment().format('YYYY-MM-DD HH:mm')
    },
    changeAppointmentItems({ items }) {
      if(this.defaultHealthPlan) {
        this.defaultHealthPlan = items[0].health_plan
      }
      if (this.initialItem === null) {
        this.initialItem = items.map(o => ({ ...o }))
      }
      if (JSON.stringify(this.currentItems) === JSON.stringify(items)) {
        return
      }
      if (JSON.stringify(this.initialItem) !== JSON.stringify(items)) {
        this.currentItems = items
        this.form.appointment_items = items
        this.forceReloadDate()
      }
    },
    handleRemoveItem(itemId) {
      this.itemsToDelete.push(itemId)
    },
    onStartClick(appointment) {
      if (['MISSED', 'CANCELLED', 'FINISHED'].includes(this.form.status)) {
        this.$toast.warning(
          'O status deste agendamento não permite iniciar um atendimento'
        )
        return
      }
      this.handleStartAttendance(this.form)
    },
    goTo(path) {
      this.$router.push(path)
    },
    handleStartAttendance(appointment) {
      const attendance = appointment?.attendance?.find(
        att => att.type === 'ATTENDANCE'
      )

      if (attendance) {
        this.goTo(
          `/pacientes/${appointment.patient.id}/consulta/${attendance?.id}`
        )
      } else {
        this.startAttendance()
      }
    },
    async startAttendance() {
      const isLoading = this.$loading.show()

      try {
        const formId = await this.getDefaultAttendanceForm()
        const response = await this.api.startAttendance(
          this.clinic.id,
          this.form.patient.id,
          this.form.id,
          formId
        )
        this.$router.push(
          '/pacientes/' + this.form.patient.id + '/consulta/' + response.data.id
        )
      } catch (error) {
        this.$toast.error(error.message)
        this.$router.push(
          '/pacientes/' +
            this.form.patient.id +
            '/consulta/' +
            error.response.data.id
        )
      } finally {
        isLoading.hide()
      }
    },
    deleteAppointment() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir este agendamento? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          const isLoading = this.$loading.show()
          this.api
            .destroyAppointment(this.form.id)
            .then(async () => {
              this.$bvModal.hide('appointment-modal')
              this.loadAppointments()
              this.$toast.success('Agendamento cancelado com sucesso')
              this.$emit('deleted-appointment', this.form.id)
            })
            .catch(err => this.$toast.error(err.message))
            .finally(() => {
              isLoading.hide()
            })
        }
      })
    },
    async hasAppointmentOnPeriod() {
      if (!this.form?.room?.id || this.form?.type === 'Encaixe') return false

      const startDateTime =
        this.form?.start_datetime ??
        this.moment(
          this.moment(this.form?.date).format('YYYY-MM-DD') +
            ' ' +
            this.form?.startTime +
            ':00'
        )
          .add(1, 'seconds')
          .format('YYYY-MM-DD HH:mm:ss')
      const endDateTime =
        this.form?.end_datetime ??
        this.moment(
          this.moment(this.form?.date).format('YYYY-MM-DD') +
            ' ' +
            this.form?.endTime +
            ':00'
        )
          .subtract(1, 'seconds')
          .format('YYYY-MM-DD HH:mm:ss')

      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.hasAppointmentOnPeriod(
          this.clinic.id,
          this.form.room.id,
          startDateTime,
          endDateTime,
          {
            exceptions: this.form.id ? [this.form.id] : []
          }
        )
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async handleSaveAppointment() {
      if (!this.validateForm()) return
      if (!this.checkTelemedicine()) return

      if (this.form.startTime > this.form.endTime) {
        this.$toast.warning(
          'O horário de início informado não pode ser posterior ao horário final!'
        )
        return
      }
      this.savingAppointment = true
      if (this.form.status === 'CANCELLED' || this.form.status === 'CANCELLED_BY_PATIENT' || this.form.status === 'FINISHED' ) {
          this.validatedStatusButton = true
          this.validatedStatus = true
        }
      if (this.form.id) this.updateAppointment()
      else this.saveAppointment()
    },
    getPatientHealthPlans() {
      if (!this.clinic.id || !this.form.patient) return

      const loading = this.$loading.show()

      this.api
        .getPatientHealthPlans(this.clinic.id, this.form.patient.id)
        .then(res => {
          this.healthPlans = res.data
            .filter(el => el.clinic_health_plan_id)
            .map(el => {
              return { ...el.health_plan }
            })
          this.allPlans = res.data
            .filter(data => data.plan_id)
            .map(el => el.plan)
          if (!this.appointmentId) {
            this.defaultHealthPlan = this.healthPlans.find(
              el => !el.$isDisabled && el.type === 'private_health' ? 'private_health' : 'particular'
            )
            this.updatePlans(this.defaultHealthPlan)
          }
        })
        .catch(err => {
          console.error('Erro ao buscar plano de saúde do paciente', err)
        })
        .finally(() => loading.hide())
    },
    updatePlans(healthPlan){
      this.plans = this.allPlans.filter(el => el.health_plan_id === healthPlan.id)
    },
    async saveAppointment() {
      const isLoading = this.$loading.show()
      try {
        await this.addOnlendingProfilesIfNecessary()
        const payloadData = this.getPayloadData()
        const { data } = await this.api.createAppointment(payloadData)
        this.$set(this.form, 'id', data.id)
        this.$toast.success('Agendamento salvo com sucesso')
        this.loadAppointments()
        this.$emit('created-appointment', data)
        this.eventHub.$emit('success-scheduling-created-appointment', {
          appointmentId: data.id,
          patientId: data.patient_id
        })
        this.setAppointmentId(data.id)
      } catch (error) {
        // overbooking error has code 2
        if(error.code && error.code === 2) {
          this.$refs.dateTimeMod.busyTime = []
          this.$refs.dateTimeMod.getBusyDateTimes(this.form.date)
        }
        this.$toast.warning(error.message)
      } finally {
        isLoading.hide()
        this.savingAppointment = false;
        this.loadAppointmentDetails()
      }
    },
    async updateAppointment() {
      const isLoading = this.$loading.show()
      await this.addOnlendingProfilesIfNecessary()
      const data = this.getPayloadData()
      this.api
        .updateAppointment(this.form.id, data)
        .then(() => {
          this.itemsToDelete = []
          this.$toast.success('Agendamento atualizado com sucesso')
          this.loadAppointments()
          this.$emit('updated-appointment', this.form.id)
        })
        .catch(err => {
          if (err.code && err.code === 3) {
            this.$toast.warning(err.message)
          } else {
            this.$toast.error(err.message)
          }
        })
        .finally(() => {
          isLoading.hide()
          this.loadAppointmentDetails()
        })
    },
    getPayloadData() {
      return {
        id: this.form.id,
        clinic_id: this.clinic.id,
        professional_id: this.form.professional
          ? this.form.professional.id
          : null,
        patient_id: this.form.patient ? this.form.patient.id : null,
        room_id: this.form.room ? this.form.room.id : null,
        type: this.form.type,
        overlap_reason: this.form.overlap_reason,
        start_datetime:
          this.moment(this.form.date).format('YYYY-MM-DD') +
          ' ' +
          this.form.startTime +
          ':00',
        end_datetime:
          this.form.endTime < this.form.startTime
            ? this.moment(this.form.date).add(1, 'd').format('YYYY-MM-DD') +
              ' ' +
              this.form.endTime +
              ':00'
            : this.moment(this.form.date).format('YYYY-MM-DD') +
              ' ' +
              this.form.endTime +
              ':00',
        telemedicine: Boolean(this.form.telemedicine_link),
        telemedicine_link: this.form.telemedicine_link,
        description: this.form.description || null,
        status: !this.form.status ? 'SCHEDULED' : this.form.status,
        status_time: this.form.status_time,
        items_to_delete: this.itemsToDelete,
        equipment_id: this.form.equipment?.id,
        appointment_items: this.form.appointment_items
          .filter(el => el.item)
          .map(el => ({
            id: el.id,
            item_id: el.item.id,
            item_type: el.item.type,
            health_plan_id: el.health_plan.id,
            plan_id: el.plan?.id,
            value: el.value,
            item_profiles: el.item_profiles
              .filter(
                itemProfile => itemProfile.profile && itemProfile.beneficiary
              )
              .map(itemProfile => ({
                id: itemProfile.id || null,
                appointment_item_id: itemProfile.appointment_item_id || null,
                item_id: el.item.id,
                profile_id: itemProfile.profile.id,
                beneficiary_id: itemProfile.beneficiary.id
              }))
          })),
        priority_level: this.form.priority_level
      }
    },
    validateTelemedicine() {
      const hasTelemedicine = this.form?.appointment_items?.some(
        el => el?.item?.item?.type === 'TELEMEDICINE'
      )
      if (!hasTelemedicine) {
        return null
      }

      if (
        this.form?.patient &&
        this.form?.professional &&
        (!this.form?.patient?.email || !this.form?.professional?.email)
      ) {
        this.$toast.warning(
          'Agendamentos de telemedicina devem conter emails válidos no cadastro do paciente e do médico'
        )

        this.form.telemedicine_link = ''
        return false
      }

      return true
    },
    checkTelemedicine() {
      const hasTelemedicine = this.form?.appointment_items?.some(
        el => el?.item?.item?.type === 'TELEMEDICINE'
      )
      if (hasTelemedicine) {
        if (
          this.form?.patient &&
          this.form?.professional &&
          (!this.form?.patient?.email || !this.form?.professional?.email)
        ) {
          this.$toast.error(
            'Agendamentos de telemedicina devem conter emails válidos no cadastro do paciente e do médico'
          )
          this.form.telemedicine_link = ''
          return false
        }
      }
      return true
    },
    validateDate() {
      const requiredFields = ['type', 'date', 'startTime', 'endTime']
      const missingFields = requiredFields.filter(field => !this.form[field])

      // ignora a validação em caso de ser atualização
      if (!this.form.id) {
        if (this.form.type === 'Encaixe' && !this.form.overlap_reason) {
          missingFields.push('Problema de encaixe')
        }
      }

      if (missingFields.length > 0) {
        return missingFields
      }
      return false
    },
    validateItems() {
      return !(
        JSON.stringify(this.initialItem) ===
        JSON.stringify(this.form.appointment_items)
      )
    },
    validatePlan() {
      return !!this.form.appointment_items
        .filter(el => el.item)
        .every(el => el.item && el.health_plan)
    },
    validateForm() {
      this.validated = true
      const errorMessages = [
        this.form.patient ? null : 'Erro no paciente',
        this.form.type ? null : 'Erro no tipo de atendimento',
        this.form.professional || this.form.room ? null : 'Erro no medico/sala',
        this.validateItems() ? null : 'Erro nos items',
        this.validatePlan() ? null : 'Erro nos planos',
        this.validateDate() === false ? null : 'Erro na data'
      ].filter(errorMessage => errorMessage !== null)

      if (errorMessages.length > 0) {
        // vamos deixar o console log pra termos visão de qual erro tá vindo
        console.log(errorMessages)
        this.$toast.warning('Preencha todos os campos')
        return false
      }

      if (this.constraintAgeAction === 'block') {
        this.$toast.warning('O paciente está fora do limite de idade determinado para o Dr(a) ' + this.form.professional.name)
        return false
      }

      if (this.constraintHealthPlanAction === 'block') {
        this.$toast.warning('O paciente está fora das regras definidas para o Dr(a) ' + this.form.professional.name)
        return false
      }

      if(this.form.type !== 'Encaixe' && !this.form.id) {
        const firstStartTime = this.$refs?.dateTimeMod?.startTimeOptions[0]
        const lastEndTime = this.$refs?.dateTimeMod?.endTimeOptions[this.$refs?.dateTimeMod?.endTimeOptions?.length -1]

        if(!((this.form.endTime >= firstStartTime && this.form.endTime <= lastEndTime) || (this.form.startTime >= firstStartTime && this.form.startTime <= lastEndTime))){
          this.$toast.warning('Selecione um horário válido')
          return false
        }
      }

      return true;
    },

    async loadAppointmentDetails() {
      if (!this.appointmentId) return
      this.loading = true
      try {
        const res = await this.api.getAppointmentDetails(this.appointmentId)
        
        this.form = {
          ...res.data,
          date: this.moment(res.data.start_datetime).toDate(),
          startTime: this.moment(res.data.start_datetime).format('HH:mm'),
          endTime: this.moment(res.data.end_datetime).format('HH:mm')
        }

        if (this.form.status === 'FINISHED' && this.form.id) {
          this.userCanSave = userHasPermission('FpAgen8')
        }

        if (this.form.room_id) {
          await this.getEquipments()
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
        this.savingAppointment = false
        this.onRenderStartTime = this.form.startTime
      }
    },
    async getDefaultAttendanceForm() {
      const user = getCurrentUser()
      try {
        const response = await this.api.getForms(this.clinic.id, user.id)
        const form = response.data.find(f => f.default)
        return form?.id
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    getTelemedicineLink(form) {
      return form.telemedicine_link?.includes('@')
        ? form.telemedicine_link.split('@')[1]
        : form.telemedicine_link
    },
    openTelemedLink(form) {
      window.open(this.getTelemedicineLink(form), '_blank')
    },
    selectProfessional(professional) {
      this.form.professional = professional
      if(this.form.appointment_items.length) {
        this.constraintHealthPlanValidation()
      }
    },
    async getEquipments() {
      try {
        const { data } = await this.api.getAllFromClinicIdAndRoomId(
          this.clinic.id,
          this.form.room?.id
        )
        this.equipments = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async onSelectRoom() {
      this.form.equipment = null
      await this.getEquipments()
      this.clearDateTime()
    },
    clearRoom() {
      this.form.room = null
      this.form.equipment = null
      this.forceReloadRoom()
      this.forceReloadDate()
    },
    clearProfessional() {
      this.form.professional = null
      this.forceReloadDate()
    },
    clearDateTime() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.dateTimeMod?.clearData()
      this.form.endTime = null
      this.form.end_datetime = null
      this.form.startTime = null
      this.form.start_datetime = null
      this.forceReloadDate()
    },
    async mapAppointmentProcedures() {
      const procedures = []

      for (const appointmentItem of this.form.appointment_items) {
        procedures.push({
          item_id: appointmentItem.item?.id,
          item: appointmentItem.item,
          rooms: appointmentItem?.rooms || []
        })
      }
      this.proceduresRoom = procedures
    },
    async printResume() {
      this.printingResume = true
      try {
        const { data } = await this.api.printAppointmentResume(this.form.id)
        saveAs(
          new Blob([data], { type: 'application/pdf;charset=utf-8' }),
          `${this.form?.patient?.name} - ${this.moment().format(
            'DD-MM-YYYY[-]HH:mm'
          )}.pdf`
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.printingResume = false
      }
    },
    async addOnlendingProfilesIfNecessary() {
      if (!showOnlending) return

      const professionalId = this.form.professional?.id
      const itemIds = this.form.appointment_items
        .filter(item => !item.item_profiles.length)
        .map(el => el.item.id)
      
      if (!itemIds.length || !professionalId) return

      const { data } = await this.api.getBeneficiaryFromAppointmentItems(
        this.clinic.id,
        professionalId,
        itemIds
      )

      for (const appointmentItem of this.form.appointment_items) {
        if (data[appointmentItem.item.id] && !appointmentItem.item_profiles.length) {
          appointmentItem.item_profiles.push(data[appointmentItem.item.id])
        }
      }
    },
    async getConstraintByProfessional(professionalId) {
      const { data } = await this.api.getConstraintByProfessional(
        this.clinic.id,
        professionalId
      );

      this.professionalConstraint = data;
    },
    async constraintAgeValidation() {
      this.constraintAgeAction = 'allow';

      if (this.professionalConstraint) {
        for await (const constraint of this.professionalConstraint) {
          // In case that health plan is null, is a age constraint
          if (!constraint.health_plan_id) {
            for await (const config of constraint.constraint_config) {
              if (this.patientAge < config.min || this.patientAge > config.max) {
                this.constraintAgeAction = constraint.constraint_action;

                return;
              }
            }
          }
        }
      }
    },
    async constraintHealthPlanValidation() {
      this.constraintHealthPlanAction = 'allow';

      if (this.professionalConstraint && !this.form.id) {
        for await (const constraint of this.professionalConstraint) {
          // In case that health plan is not null, is a health plan constraint
          if (constraint.health_plan_id === this.defaultHealthPlan.id && constraint.constraint_config.days.includes(this.moment(this.form.date).format('dddd').toLowerCase())) {
            const year = this.moment(this.form.date).format('YYYY');
            const month = this.moment(this.form.date).format('MM');

            const {data} = await this.api.getConstraintByHealthPlan(
              this.clinic.id,
              this.defaultHealthPlan.id,
              this.form.appointment_items[0]?.item?.type.toUpperCase(),
              year,
              month
            );

            // Validate if the amount of appointments is greater than the limit
            if (constraint.constraint_config.limit === 'day') {
              const day = this.moment(this.form.date).format('DD');

              const total = data.filter(amount => amount.V_DAY === day)[0]?.total || 0;
              if(constraint.constraint_config.types.includes(this.form.appointment_items[0].item?.type)) {
                if (total >= constraint.constraint_config.amount) {
                  this.constraintHealthPlanAction = constraint.constraint_action;
                  return;
                }
              } else {
                this.constraintHealthPlanAction = 'allow';
              }
            }
            // Validate fi the amount of appoint is greater than the limit in the week
            if (constraint.constraint_config.limit === 'week') {
              const week = this.moment(this.form.date).format('WW');

              const filtered = data.filter(amount => amount.V_WEEK === week);

              const total = filtered.reduce((acc, amount) => acc + amount.total, 0);

              if(constraint.constraint_config.types.includes(this.form.appointment_items[0].item.type)) {
                if (total >= constraint.constraint_config.amount) {
                  this.constraintHealthPlanAction = constraint.constraint_action;
                  return;
                }
              } else {
                this.constraintHealthPlanAction = 'allow';
              }
            }

            // Validate if the amount of appointment is greater than the limit in the month
            if (constraint.constraint_config.limit === 'month') {
              const month = this.moment(this.form.date).format('MM');

              const filtered = data.filter(amount => amount.V_MONTH === month);

              const total = filtered.reduce((acc, amount) => acc + amount.total, 0);

              if(constraint.constraint_config.types.includes(this.form.appointment_items[0].item.type)) {
                if (total >= constraint.constraint_config.amount) {
                  this.constraintHealthPlanAction = constraint.constraint_action;
                  return;
                }
              } else {
                this.constraintHealthPlanAction = 'allow';
              }
            }
            // Validate if this day is avaliable to this health plan
            if (!constraint.constraint_config.days.includes(this.moment(this.form.date).format('dddd').toLowerCase())) {
              this.constraintHealthPlanAction = constraint.constraint_action;

              return;
            }
          }
        }
      }
      this.constraintHealthPlanVariant()
    },
    showBrowserPrintModal(){
      this.showBrowserPrint = true
    },
    getPatientIdPrintSetttings(){
      try {
        this.api.getBraceletPrintSettings(this.clinic.id)
        .then(({data}) => {
          this.patientIdPrintSettings = data
          if(data.active) this.printSettingsActive = true
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    constraintHealthPlanVariant() {
      if (this.constraintHealthPlanAction === 'block') {
        return 'danger';
      }

      return 'warning';
    }
  },
  watch: {
    appointmentId: {
      async handler(id) {
        if (id) {
          await this.loadAppointmentDetails()
          if (
            this.form.status === 'PRE_FINISHED' ||
            this.form.status === 'FINISHED' ||
            this.form.status === 'DILATING' ||
            this.form.status === 'EXAMINATING' ||
            this.form.status === 'HAPPENING' ||
            this.form.status === 'CANCELLED_BY_PATIENT' ||
            this.form.status === 'IN_PRE_CONSULTATION' ||
            this.form.status === 'CANCELLED'
          ) {
            this.validatedStatus = true
          }
        }
      },
      immediate: true,
      deep: true
    },
    'form.patient': function (newPatient, oldPatient) {
      if (newPatient !== oldPatient) {
        this.patientAge = this.moment().diff(newPatient?.birthday, 'years');

        this.$emit('setPatient', newPatient, oldPatient)
        this.validateTelemedicine()
        this.getPatientHealthPlans()
        this.constraintAgeValidation();
      }
    },
    'form.professional': {
      async handler(professional) {
        if (professional) {
          await this.getConstraintByProfessional(professional.id)
        }

        // We need to validate this way because patient can have 0 years
        // When patient has 0 years, the validation is not working - because 0 == false
        if (Number.isInteger(this.patientAge)) {
          this.constraintAgeValidation();
        }

        if (this.defaultHealthPlan && this.form.date && this.form.appointment_items.length > 0) {
          this.constraintHealthPlanValidation()
        }

        this.validateTelemedicine()
      },
      immediate: true
    },
    defaultHealthPlan: {
      handler(value) {

        if (this.form.date && this.form.professional.id && this.form.appointment_items.length > 0) {
          this.constraintHealthPlanValidation()
        }
      },
      immediate: true
    },
    patientProps: {
      handler(patient) {
        if (!patient?.id) return
        this.form.patient = patient
      },
      immediate: true,
      deep: true
    },
    'form.appointment_items': {
      handler(value) {
        if (value) {
          const items = value.map(element => {
            return element.item?.type ? element.item : element.item?.item
          })

          const surgeryProcedures = items
            .map(item => {
              return item?.type ? item : item?.item
            })
            .filter(item => item?.type === 'SURGICAL')

          this.$emit('set-surgery-procedures', surgeryProcedures)
          this.mapAppointmentProcedures()
          this.savingAppointment = false
        }

        if (this.defaultHealthPlan && this.form.professional?.id && this.form.date) {
          this.constraintHealthPlanValidation()
        }
      },
      immediate: true,
      deep: true
    },
    dateTime() {
      this.forceReloadDate()
    },
    'form.date': function (val) {
      this.forceReloadDate()
      this.$emit('appointmentDate', val)

      if (this.defaultHealthPlan && this.form.professional.id && this.form.appointment_items.length > 0) {
        this.constraintHealthPlanValidation()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.appoinment-config {
  margin: 0;
}
.appointment-content {
  padding: 10px 0 30px 0;
}
.patient-status-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  .vertical-divider {
    width: 1px;
    height: 100px;
    background-color: var(--neutral-200);
  }

  .status-priority-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--neutral-200);
  margin-bottom: 20px;
}
.remove {
  color: var(--states-error) !important;
}
.telemedicine-export {
  position: absolute;
  top: 34px;
  right: 10px;
  cursor: pointer;
}
.no-equipments {
  font-size: 14px;
  font-style: italic;
  color: var(--type-active);
}
.patient-status-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  .vertical-divider {
    width: 1px;
    height: 100px;
    background-color: var(--neutral-200);
  }
}
.loading-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
.prt-button {
  margin-right: 5px;
  border-radius: 6px;
}

.constraint-block {
  background-color: #FBA59D;
  border-color: #FBA59D;
  font-family: 'Nunito Sans';
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert-danger svg {
  filter: invert(43%) sepia(42%) saturate(3737%) hue-rotate(332deg) brightness(77%) contrast(129%)
}

.alert-warning svg {
  filter: invert(15%) sepia(57%) saturate(4885%) hue-rotate(343deg) brightness(104%) contrast(100%);
}

.constraint-warning {
  background-color: #FFEBCC;
  border-color: #FFEBCC;
  font-family: 'Nunito Sans';
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #525c7a;
}
</style>
